var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "charter" }, [
    _c("div", { staticStyle: { width: "20%" } }, [_c("plateNavigation")], 1),
    _c(
      "div",
      { staticClass: "charter-content" },
      [
        _c("carousel"),
        _c("ranking", { attrs: { msg: _vm.msg } }),
        _c("div", { staticClass: "area-content" }, [
          _c("div", [_vm._v("—")]),
          _vm._v("\n      " + _vm._s(_vm.msg.description) + "\n    ")
        ]),
        _c("div", { staticClass: "new" }, [
          _c("div", { staticClass: "newImg" }, [_vm._v("最新图片")]),
          _c("div", { staticStyle: { width: "40%" } }, [_c("newCarousel")], 1)
        ]),
        _c("div", { staticClass: "posting" }, [_vm._v("发帖")]),
        _c(
          "div",
          { staticClass: "area-list" },
          [
            _c("all", {
              on: {
                onChange: _vm.onChange,
                btnAll: _vm.btnAll,
                btnHot: _vm.btnHot,
                btnHotPost: _vm.btnHotPost,
                btnElite: _vm.btnElite
              }
            }),
            _c(
              "div",
              { staticClass: "list-item" },
              [
                _vm.isPattern
                  ? _c("imgPattren", {
                      ref: "img",
                      attrs: { pageNum: _vm.pageNum },
                      on: { getTotal: _vm.getTotal }
                    })
                  : _c("fontPattren", { ref: "font" }),
                _vm.isPagination
                  ? _c("pagination", {
                      attrs: { totalNum: _vm.totalNum },
                      on: { onChangePagination: _vm.pagination }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "posting" }, [_vm._v("发帖")]),
        _c("VueUeditor", { on: { updateDeatil: _vm.updateDeatil } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }